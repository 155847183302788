body{
    margin: 0;
    padding: 0;
}
#root{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
}

